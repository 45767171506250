import {useState, useEffect, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

export default function useCybersourceField({
  microform,
  fieldKey,
  fieldType,
  placeholder,
  domId,
}) {
  const {t} = useTranslation('payments')
  const [field, setField] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [empty, setIsEmpty] = useState(true)
  const [couldBeValid, setCouldBeValid] = useState(false)
  const [valid, setIsValid] = useState(false)
  const [card, setCard] = useState([])
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (!microform || field) return
    const localField = microform.createField(fieldType, {placeholder})
    localField.on('load', () => {
      setIsLoading(false)
    })
    localField.on('change', (data) => {
      setIsEmpty(data.empty)
      setCouldBeValid(data.couldBeValid)
      setIsValid(data.valid)
      setCard(data.card)
      if (!data.empty && !data.valid && !data.couldBeValid)
        setErrorMessage(t(`payments:CreditCardForm.fields.${fieldKey}.invalid`))
      else setErrorMessage(null)
    })

    localField.load(domId)
    setField(localField)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domId, fieldKey, fieldType, microform, placeholder])

  const onBlur = useCallback(() => {
    if (!empty && !valid) {
      const errorType = couldBeValid ? 'incomplete' : 'invalid'
      setErrorMessage(
        t(`payments:CreditCardForm.fields.${fieldKey}.${errorType}`),
      )
    } else if (empty) {
      setErrorMessage(t(`common:yup.mixed.required`))
    } else {
      setErrorMessage(null)
    }
  }, [couldBeValid, empty, fieldKey, t, valid])

  useEffect(() => {
    if (!field) return () => {}

    field.on('blur', onBlur)

    return () => field.off('blur', onBlur)
  }, [field, onBlur])

  const focusInput = useCallback(() => {
    if (!field) return

    field.focus()
  }, [field])

  return {
    isLoading,
    field,
    valid,
    couldBeValid,
    empty,
    card,
    focusInput,
    errorMessage,
  }
}
