import {useState} from 'react'
import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import useCybersourceFlex from '../../../hooks/cybersource/useCyberSourceFlex'
import {confirmCybersourcePayment} from '../../../../api'
import useFormSchema from './useFormSchema'

const useCreditCardForm = (invoiceId, authenticityToken, onSubmitPayment) => {
  const {t} = useTranslation('payments')
  const {
    cardDetails,
    cardNumberDetails,
    cardCvcDetails,
    createToken,
    acceptedCardBrands,
    isLoading: isLoadingForm = true,
    isValid: cybersourceFieldsValid,
  } = useCybersourceFlex()

  const [submitErrorMessage, setSubmitErrorMessage] = useState()

  const onSubmit = async (values, actions) => {
    createToken(
      {
        expirationMonth: values.expirationMonth.padStart(2, '0'),
        expirationYear: values.expirationYear,
      },
      async ({token: transientToken, captureContext, error}) => {
        if (error) {
          error.invalidFieldKeys.forEach((key) => {
            actions.setFieldError(key, 'Invalid')
          })

          setSubmitErrorMessage(
            t('payments:CreditCardForm.transientTokenCreationError'),
          )
          return
        }

        const {customerAccount} = await confirmCybersourcePayment({
          invoiceId,
          authenticityToken,
          transientToken,
          captureContext,
        })

        await onSubmitPayment({
          type: 'cybersourceCreditCard',
          customerAccount,
        })
      },
    )
  }

  const formSchema = useFormSchema()
  const formik = useFormik({
    initialValues: {
      nameOnCard: '',
      billingCity: '',
      billingCountry: 'Australia',
      email: '',
      expirationMonth: '',
      expirationYear: '',
    },
    validationSchema: formSchema,
    onSubmit,
  })

  return {
    formik,
    cardDetails,
    cardNumberDetails,
    cardCvcDetails,
    acceptedCardBrands,
    isLoadingForm,
    submitErrorMessage,
    isValid: formik.dirty && formik.isValid && cybersourceFieldsValid,
  }
}

export default useCreditCardForm
