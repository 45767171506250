import {camelizeKeys} from 'humps'
import {fetcher, defaultHeaders, getHeaders, postHeaders} from './fetcher'

export const verifyPayment = ({
  paymentIntentId,
  invoiceId,
  authenticityToken,
  cardPaymentToken,
}) => {
  const body = {
    invoice_id: invoiceId,
    card_payment_token: cardPaymentToken || '',
  }
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/payments/${paymentIntentId}/verify`, opts)
}

export const confirmCybersourcePayment = ({
  invoiceId,
  authenticityToken,
  transientToken,
  captureContext,
}) => {
  const body = {
    invoice_id: invoiceId,
    transient_token: transientToken || '',
    capture_context: captureContext,
  }
  const opts = postHeaders({authenticityToken, body})

  return fetcher('/api/credit_cards', opts)
}

export const confirmDirectDebitPayment = ({
  invoiceId,
  bsb,
  accountNumber,
  authenticityToken,
}) => {
  const body = {id: invoiceId, bsb, account_number: accountNumber}
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/pay_via_dd`, opts)
}

export const generateCustomerPaymentIntent = ({
  invoiceId,
  authenticityToken,
}) => {
  const body = {invoice_id: invoiceId}
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/customers/payment_intent`, opts)
}

export const retrieveSavedCreditCardsInfo = async ({
  customerId,
  authenticityToken,
}) => {
  const opts = {
    method: 'GET',
    headers: defaultHeaders({authenticityToken}),
  }

  const data = await fetcher(
    `/api/customers/${customerId}/saved_credit_cards`,
    opts,
  )

  return camelizeKeys(data, {deep: true})
}

export const retrieveInvoicePaymentInfo = async ({xeroInvoiceUUID}) => {
  const data = await fetcher(
    `/api/payments/${xeroInvoiceUUID}/payment_info`,
    getHeaders,
  )

  return camelizeKeys(data, {deep: true})
}

export const informPayment = ({
  type,
  amount,
  notes,
  invoiceId,
  authenticityToken,
}) => {
  const body = {
    outside_payment: {
      full_or_partial: type,
      amount,
      notes,
    },
  }
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/inform_payments`, opts)
}

export const createOutsidePayment = ({
  type,
  amount,
  notes,
  invoiceId,
  authenticityToken,
}) => {
  const body = {
    outside_payment: {
      full_or_partial: type,
      amount,
      notes,
    },
  }
  const opts = postHeaders({authenticityToken, body})

  return fetcher(`/api/invoices/${invoiceId}/outside_payments`, opts)
}
