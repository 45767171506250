import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {
  Box,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import PaymentMethodSection from './PaymentMethodSection'
import billPaymentPropType from '../../../utils/propTypes/billPayment'
import PayeeDetailsSection from './PayeeDetailsSection'
import PaymentDetailsSection from './PaymentDetailsSection'
import SummarySection from './SummarySection'

const BillPaymentsDrawer = ({billPayment, isOpen, onClose}) => {
  const {t} = useTranslation('billPayments')

  return (
    <Drawer isOpen={isOpen} placement="right" size="md" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid"
            borderColor="neutral.glitter"
          >
            <Text textStyle="body-intro-medium" fontSize="md">
              {t('billPayments:billPayment.drawer.title')}
            </Text>

            <CloseButton onClick={onClose} />
          </Box>
        </DrawerHeader>

        <DrawerBody overflowY="scroll" py="md">
          {billPayment && (
            <Flex direction="column" gap="md">
              <SummarySection billPayment={billPayment} />
              <Divider />
              <PayeeDetailsSection payee={billPayment.payee} />
              <Divider />
              <PaymentDetailsSection {...billPayment} />
              <Divider />
              <PaymentMethodSection paymentMethod={billPayment.paymentMethod} />
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

BillPaymentsDrawer.defaultProps = {
  billPayment: null,
}

BillPaymentsDrawer.propTypes = {
  billPayment: billPaymentPropType,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default BillPaymentsDrawer
