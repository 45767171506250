import React from 'react'
import PropTypes from 'prop-types'

const CardLogo = ({
  LogoComponent,
  detectedBrandName,
  cardBrandName,
  accepted,
}) =>
  accepted && (
    <LogoComponent
      alt={cardBrandName}
      aria-label={cardBrandName}
      sx={
        detectedBrandName && detectedBrandName !== cardBrandName
          ? {filter: 'grayscale(1)'}
          : {}
      }
    />
  )

CardLogo.defaultProps = {
  detectedBrandName: null,
}

CardLogo.propTypes = {
  LogoComponent: PropTypes.elementType.isRequired,
  detectedBrandName: PropTypes.string,
  cardBrandName: PropTypes.string.isRequired,
  accepted: PropTypes.bool.isRequired,
}

export default CardLogo
