import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import Dinero from 'dinero.js'
import {useRails} from '../../contexts/rails'
import usePaymentMethods from './hooks/usePaymentMethods'
import PayeeDetailsSection from '../BillPaymentsDrawer/PayeeDetailsSection'
import PaymentDetailsSection from '../BillPaymentsDrawer/PaymentDetailsSection'
import PaymentMethodSection from '../BillPaymentsDrawer/PaymentMethodSection'

const ConfirmationDialog = ({
  isLoading,
  isDisabled,
  formValues,
  submitForm,
}) => {
  const {t} = useTranslation('billPayments')
  const i18nPrefix = 'billPayments:billPayment.form'
  const {isOpen, onOpen, onClose} = useDisclosure()
  const cancelRef = React.useRef()
  const {
    organisation: {
      currentSupplier: {id: supplierId},
    },
  } = useRails()
  const {findPaymentMethod} = usePaymentMethods(supplierId)

  const paymentMethod = useMemo(() => {
    if (!formValues.paymentMethodId) return null

    return findPaymentMethod(formValues.paymentMethodId)
  }, [findPaymentMethod, formValues.paymentMethodId])

  const payeeDetails = {
    name: formValues.payeeName,
    email: formValues.payeeEmail,
    branchCode: formValues.branchCode,
    accountNumber: formValues.accountNumber,
    businessNumber: formValues.businessNumber,
  }

  const paymentDetails = useMemo(() => {
    if (!formValues.amount) return {}

    const amount = parseInt(formValues.amount, 10)
    const netPaymentAmount = Dinero({amount})

    return {
      netPaymentAmount,
      grossPaymentAmount: netPaymentAmount,
      paymentReference: formValues.reference,
    }
  }, [formValues.amount, formValues.reference])

  return (
    <>
      <Button
        variant="primary"
        colorScheme="primary"
        isLoading={isLoading}
        loadingText={t(`${i18nPrefix}.submitting`)}
        isDisabled={isDisabled}
        onClick={onOpen}
      >
        {t(`${i18nPrefix}.submitButton`)}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            {t(`${i18nPrefix}.confirmationDialog.title`)}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text textStyle="body-intro" pb="sm">
              {t(`${i18nPrefix}.confirmationDialog.description`)}
            </Text>
            <Flex
              direction="column"
              gap="sm"
              background="mld.neutral.100"
              p="sm"
            >
              <PayeeDetailsSection payee={payeeDetails} />
              <Divider />
              <PaymentDetailsSection {...paymentDetails} />
              <Divider />
              {paymentMethod && (
                <PaymentMethodSection paymentMethod={paymentMethod} />
              )}
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter justifyContent="flex-start" gap="sm">
            <Button
              variant="primary"
              colorScheme="primary"
              type="submit"
              isLoading={isLoading}
              loadingText={t(`${i18nPrefix}.submitting`)}
              isDisabled={isDisabled}
              onClick={submitForm}
            >
              {t(`${i18nPrefix}.confirmationDialog.confirmButton`)}
            </Button>
            <Button
              variant="secondary"
              colorScheme="primary"
              type="submit"
              onClick={onClose}
            >
              {t(`${i18nPrefix}.confirmationDialog.cancelButton`)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

ConfirmationDialog.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    paymentMethodId: PropTypes.string,
    payeeName: PropTypes.string,
    payeeEmail: PropTypes.string,
    businessNumber: PropTypes.string,
    amount: PropTypes.string,
    branchCode: PropTypes.string,
    accountNumber: PropTypes.string,
    reference: PropTypes.string,
  }).isRequired,
  submitForm: PropTypes.func.isRequired,
}

export default ConfirmationDialog
