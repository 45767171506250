import PropTypes from 'prop-types'
import DineroPropShape from './dinero'
import paymentMethodPropType from './paymentMethod'

export const billPaymentPayeePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  branchCode: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
  businessNumber: PropTypes.string.isRequired,
})

const billPaymentPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  payee: billPaymentPayeePropType.isRequired,
  netPaymentAmount: DineroPropShape.isRequired,
  grossPaymentAmount: DineroPropShape.isRequired,
  paymentReference: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  paymentMethod: paymentMethodPropType.isRequired,
})

export default billPaymentPropType
