import useSWRImmutable from 'swr/immutable'
import {useTranslation} from 'react-i18next'
import {useMicroform} from 'useMicroform'

import {fetcher} from '../../../api'
import {camelizeResult, dineroMoneyObjects} from '../../../api/serializer'
import useCybersourceField from './useCybersourceField'

export default function useCybersourceFlex() {
  const {t} = useTranslation('payments')
  const {data} = useSWRImmutable('/api/credit_cards/new', fetcher, {
    use: [camelizeResult, dineroMoneyObjects],
    revalidateOnFocus: false,
  })

  const {captureContext} = data || {}

  const {
    isLoading: isLoadingMicroform,
    microform,
    acceptedCardBrands,
  } = useMicroform({
    captureContext,
  })

  const {
    card: [cardDetails],
    ...cardNumberDetails
  } = useCybersourceField({
    microform,
    fieldKey: 'cardNumber',
    fieldType: 'number',
    placeholder: t('payments:CreditCardForm.fields.cardNumber.placeholder'),
    domId: '#card-number-container',
  })

  const cardCvcDetails = useCybersourceField({
    microform,
    fieldKey: 'cardCvc',
    fieldType: 'securityCode',
    placeholder: t('payments:CreditCardForm.fields.cardCvc.placeholder'),
    domId: '#card-cvc-container',
  })

  const createToken = (options, callback) => {
    if (!microform) return

    microform.createToken(options, (error, token) => {
      if (error) {
        // TODO: Send error details to Rollbar
        const parsedError = {
          reason: error.reason,
          invalidFieldKeys: error.details.map(({location}) => location),
        }

        callback({token, captureContext, error: parsedError})
      } else {
        callback({token, captureContext})
      }
    })
  }

  return {
    isLoading:
      isLoadingMicroform ||
      cardNumberDetails.isLoading ||
      cardCvcDetails.isLoading,
    cardNumberDetails,
    cardCvcDetails,
    cardDetails,
    createToken,
    acceptedCardBrands,
    isValid: cardNumberDetails.valid && cardCvcDetails.valid,
  }
}
