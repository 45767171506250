import React from 'react'
import {useTranslation} from 'react-i18next'
import DrawerFieldItem from './DrawerFieldItem'
import DrawerSection from './DrawerSection'
import {billPaymentPayeePropType} from '../../../utils/propTypes/billPayment'
import {formatAccountNumber} from '../../../utils/formatter'

const PayeeDetailsSection = ({payee}) => {
  const {t} = useTranslation('billPayments')

  return (
    <DrawerSection
      title={t('billPayments:billPayment.drawer.payeeSectionTitle')}
    >
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.name')}
        value={payee.name}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.email')}
        value={payee.email}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.branchCode')}
        value={payee.branchCode}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.accountNumber')}
        value={formatAccountNumber(payee.accountNumber)}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.businessNumber')}
        value={payee.businessNumber}
      />
    </DrawerSection>
  )
}

PayeeDetailsSection.propTypes = {
  payee: billPaymentPayeePropType.isRequired,
}

export default PayeeDetailsSection
