import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import {Skeleton} from '@chakra-ui/react'
import Input from '../../Input'

const FormikField = ({isLoading, formik, ...fieldProps}) => {
  const {t} = useTranslation('payments')
  return (
    <Skeleton isLoaded={!isLoading}>
      <Input
        height="52px"
        mb="0"
        {...fieldProps}
        placeholder={t(
          `payments:CreditCardForm.fields.${fieldProps.name}.placeholder`,
        )}
        value={formik.values[fieldProps.name]}
        errorMessage={
          formik.touched[fieldProps.name] && formik.errors[fieldProps.name]
        }
        isInvalid={Boolean(
          formik.touched[fieldProps.name] && formik.errors[fieldProps.name],
        )}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Skeleton>
  )
}

FormikField.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formik: PropTypes.object.isRequired,
}

export default FormikField
