import React from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Spacer} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'

const SubmitButton = ({isSubmitting, isDisabled, creditCardPaymentAmount}) => {
  const {t} = useTranslation('payments')

  return (
    <Box
      display="flex"
      flexDirection={{base: 'column-reverse', md: 'row'}}
      alignItems="center"
      justifyContent="space-between"
    >
      <Spacer />
      <Button
        variant="primary"
        colorScheme="primary"
        type="submit"
        isLoading={isSubmitting}
        isDisabled={isDisabled}
      >
        {t('payments:CreditCardForm.submitButtonLabel', {
          creditCardPaymentAmount,
        })}
      </Button>
    </Box>
  )
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  creditCardPaymentAmount: PropTypes.string.isRequired,
}

export default SubmitButton
