import {useEffect, useState} from 'react'
import {jwtDecode} from 'jwt-decode'

export function useMicroform({captureContext}) {
  const [microform, setMicroform] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [acceptedCardBrands, setAcceptedCardBrands] = useState([])

  useEffect(() => {
    if (!captureContext) return () => {}

    const {
      ctx: [
        {
          data: {allowedCardNetworks, clientLibrary},
        },
      ],
    } = jwtDecode(captureContext)

    setAcceptedCardBrands(allowedCardNetworks)

    const script = document.createElement('script')
    script.src = clientLibrary
    script.async = true
    script.onload = () => {
      setIsLoading(false)
      // eslint-disable-next-line no-undef
      const flex = new Flex(captureContext)
      const localMicroform = flex.microform({
        styles: {
          input: {
            'font-size': '16px',
            color: '#1A202C',
            'font-family': 'AktivGrotesk, sans-serif',
            'font-weight': '300',
            'line-height': '24px',
          },
          '::placeholder': {
            color: '#718096',
          },
        },
      })
      setMicroform(localMicroform)
    }
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [captureContext])

  return {
    isLoading,
    microform,
    acceptedCardBrands,
  }
}
