import React from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from 'react-i18next'
import DrawerFieldItem from './DrawerFieldItem'
import DrawerSection from './DrawerSection'
import DineroPropShape from '../../../utils/propTypes/dinero'

const PaymentDetailsSection = ({
  netPaymentAmount,
  grossPaymentAmount,
  paymentReference,
}) => {
  const {t} = useTranslation('billPayments')

  return (
    <DrawerSection
      title={t('billPayments:billPayment.drawer.paymentSectionTitle')}
    >
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.paymentAmount')}
        value={netPaymentAmount.toFormat()}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.feeAmount')}
        value={grossPaymentAmount.subtract(netPaymentAmount).toFormat()}
      />
      <DrawerFieldItem
        label={t('billPayments:billPayment.drawer.fields.reference')}
        value={paymentReference}
      />
    </DrawerSection>
  )
}

PaymentDetailsSection.propTypes = {
  netPaymentAmount: DineroPropShape.isRequired,
  grossPaymentAmount: DineroPropShape.isRequired,
  paymentReference: PropTypes.string.isRequired,
}

export default PaymentDetailsSection
