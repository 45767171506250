import useSWR from 'swr'
import {camelize} from 'humps'
import {camelizeResult, dineroMoneyObjects} from '../../../../api/serializer'
import {fetcher} from '../../../../api'

const paymentMethodBody = (paymentMethod) =>
  paymentMethod[camelize(paymentMethod.type)]

export default function usePaymentMethods(supplierId) {
  const {data, isLoading} = useSWR(
    `/api/suppliers/${supplierId}/payment_methods`,
    fetcher,
    {
      use: [camelizeResult, dineroMoneyObjects],
    },
  )

  const {paymentMethods: unfilteredPaymentMethods = []} = data || {}

  const paymentMethods = unfilteredPaymentMethods.filter(
    (paymentMethod) => paymentMethodBody(paymentMethod).status === 'active',
  )

  const groupedPaymentMethods = paymentMethods.reduce(
    (result, paymentMethod) => {
      const type = camelize(paymentMethod.type)

      return {...result, [type]: [...(result[type] || []), paymentMethod]}
    },
    {debitAgreement: []},
  )

  const findPaymentMethod = (paymentMethodId) =>
    paymentMethods.find(
      (paymentMethod) =>
        paymentMethodBody(paymentMethod).id === paymentMethodId,
    ) || {}

  return {
    paymentMethods,
    groupedPaymentMethods,
    findPaymentMethod,
    isLoading,
  }
}
