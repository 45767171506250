import React from 'react'
import PropTypes from 'prop-types'
import {Box, Input, Skeleton, Text} from '@chakra-ui/react'
import theme from '../../../theme'
import input from '../../../theme/components/input'

const microformStyles = {
  height: '52px',
  '&.flex-microform-focused': {
    outline: 'none',
    borderColor: 'mld.neutral.700',
    boxShadow: `${theme.colors.mld.primary[200]} 0px 0px 5px`,
  },
}

const CyberSourceField = ({
  errorMessage,
  focusInput,
  iframeIdentifier,
  isLoading,
  ...boxProps
}) => {
  const stopPropagation = (e) => e.stopPropagation()
  return (
    <>
      <Skeleton isLoaded={!isLoading} display={!isLoading ? 'none' : 'block'}>
        {/* Add placeholder skeleton input for keeping the sizing correct for the loading state */}
        <Input height="52px" />
      </Skeleton>
      <Box
        {...boxProps}
        display={isLoading ? 'none' : 'block'}
        onClick={focusInput}
        cursor="text"
      >
        <Box
          id={iframeIdentifier}
          {...input.variants.outline().field}
          px="sm"
          mb="xxs"
          border="1px solid"
          borderRadius="md"
          borderColor={errorMessage ? 'mld.alert.700' : 'mld.neutral.700'}
          sx={microformStyles}
        />
        <Skeleton isLoaded={!isLoading} onClick={stopPropagation} cursor="auto">
          <Text textStyle="body-detail" color="mld.alert.700" width="100%">
            {errorMessage}
          </Text>
        </Skeleton>
      </Box>
    </>
  )
}

CyberSourceField.defaultProps = {
  errorMessage: '',
}

CyberSourceField.propTypes = {
  iframeIdentifier: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  focusInput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default CyberSourceField
