import React from 'react'
import PropTypes from 'prop-types'
import {Divider, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Card} from '../../Card'

const SurchargeSection = ({
  payerCreditCardSurcharged,
  creditCardSurchargePercentage,
  creditCardSurchargeFixedAmount,
  creditCardPaymentAmount,
  dueAmount,
  creditCardSurchargeAmount,
}) => {
  const {t} = useTranslation('payments')

  if (!payerCreditCardSurcharged) return null

  return (
    <Card bg="background.purple" border="0" mb="md">
      <Text textStyle="body-small">
        {t('payments:CreditCardForm.SurchargeSection.description', {
          creditCardSurchargePercentage,
          creditCardSurchargeFixedAmount,
        })}
      </Text>
      <Text mt="md" my="xs" display="flex" justifyContent="space-between">
        <Text as="span" textStyle="body-small">
          {t('payments:CreditCardForm.SurchargeSection.invoiceAmountLabel')}
        </Text>
        <Text as="span" textStyle="body-small" fontWeight="medium">
          {dueAmount}
        </Text>
      </Text>
      <Text my="xs" display="flex" justifyContent="space-between">
        <Text as="span" textStyle="body-small">
          {t('payments:CreditCardForm.SurchargeSection.surchargeLabel')}
        </Text>
        <Text as="span" textStyle="body-small" fontWeight="medium">
          {creditCardSurchargeAmount}
        </Text>
      </Text>
      <Divider variant="dark" />
      <Text mt="xs" display="flex" justifyContent="space-between">
        <Text as="span" textStyle="body-small">
          {t('payments:CreditCardForm.SurchargeSection.totalLabel')}
        </Text>
        <Text as="span" textStyle="body-small" fontWeight="medium">
          {creditCardPaymentAmount}
        </Text>
      </Text>
    </Card>
  )
}

SurchargeSection.propTypes = {
  payerCreditCardSurcharged: PropTypes.bool.isRequired,
  creditCardSurchargePercentage: PropTypes.number.isRequired,
  creditCardSurchargeFixedAmount: PropTypes.string.isRequired,
  creditCardPaymentAmount: PropTypes.string.isRequired,
  dueAmount: PropTypes.string.isRequired,
  creditCardSurchargeAmount: PropTypes.string.isRequired,
}

export default SurchargeSection
